import { BridgeUserModel } from "../model/bridgeUserModel";
import { PendoModel } from "../model/pendoModel";
import { UserAccount } from "../model/userAccountModel";
import { UserReport } from "../model/userReportsModel";

const ACCOUNTING_DASHBOARD_INSATNACE_ID = "accounting_dashboard_instance_id";
const ACCOUNTING_REPORT_INSATNACE_ID = "accounting_report_instance_id";
const PENDO_MODEL = "pendo_model";
const USER_REPORTS = "user_reports";
const USER_ACCOUNT = "user_account";
const USER_MSTR_ID = "user_mstr_id";
const BRIDGE_USER_MODEL = "bridge_user_model";

export const getAccountingDashboardInstanceId = () =>
  sessionStorage.getItem(ACCOUNTING_DASHBOARD_INSATNACE_ID);

export const getAccountingReportInstanceId = () =>
  sessionStorage.getItem(ACCOUNTING_REPORT_INSATNACE_ID);

export const setAccountingDashboardInstanceId = (id: string) =>
  sessionStorage.setItem(ACCOUNTING_DASHBOARD_INSATNACE_ID, id);

export const setAccountingReportInstanceId = (id: string) =>
  sessionStorage.setItem(ACCOUNTING_REPORT_INSATNACE_ID, id);

export const clearAccountingInstanceIds = () => {
  sessionStorage.removeItem(ACCOUNTING_DASHBOARD_INSATNACE_ID);
  sessionStorage.removeItem(ACCOUNTING_REPORT_INSATNACE_ID);
};

export const setPendoModel = (pendoModel: PendoModel) =>
  sessionStorage.setItem(PENDO_MODEL, JSON.stringify(pendoModel));

export function getPendoModel(): PendoModel | undefined {
  const pendoModel = sessionStorage.getItem(PENDO_MODEL);
  if (pendoModel) {
    return JSON.parse(pendoModel) as PendoModel;
  }
  return undefined;
}

export function clearPendoModel() {
  sessionStorage.removeItem(PENDO_MODEL);
}

export function setUserReports(userReports: UserReport[]) {
  sessionStorage.setItem(USER_REPORTS, JSON.stringify(userReports));
}

export function getUserReports(): UserReport[] {
  const userReportsFromSessionStorage = sessionStorage.getItem(USER_REPORTS);
  if (userReportsFromSessionStorage) {
    return JSON.parse(userReportsFromSessionStorage) as UserReport[];
  }
  return [];
}

export function clearUserReports() {
  sessionStorage.removeItem(USER_REPORTS);
}

export function setUserAccount(userAccount: UserAccount) {
  sessionStorage.setItem(USER_ACCOUNT, JSON.stringify(userAccount));
}

export function getUserAccount(): UserAccount | undefined {
  const userAccountsFromSessionStorage = sessionStorage.getItem(USER_ACCOUNT);
  if (userAccountsFromSessionStorage) {
    return JSON.parse(userAccountsFromSessionStorage) as UserAccount;
  }
  return undefined;
}

export function clearUserAccount() {
  sessionStorage.removeItem(USER_ACCOUNT);
}

export const setUserMstrId = (id: string) =>
  sessionStorage.setItem(USER_MSTR_ID, id);

export const getUserMstrId = () => sessionStorage.getItem(USER_MSTR_ID);

export const clearUserMstrId = () => {
  sessionStorage.removeItem(USER_MSTR_ID);
};

export const setBridgeUserModel = (model: BridgeUserModel) =>
  sessionStorage.setItem(BRIDGE_USER_MODEL, JSON.stringify(model));

export const getBridgeUserModel = () => {
  const model = sessionStorage.getItem(BRIDGE_USER_MODEL);
  if (model) {
    return JSON.parse(model) as BridgeUserModel;
  }
  return undefined;
};

export const clearBridgeUserModel = () => {
  sessionStorage.removeItem(BRIDGE_USER_MODEL);
};
