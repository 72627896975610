import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { UserAccount } from "../model/userAccountModel";
import { UserReport } from "../model/userReportsModel";
import {
  setUserReports,
  getUserReports as getUserReportsFromSession,
  clearUserReports as clearUserReportsFromSession,
  setUserAccount,
  getUserAccount as getUserAccountFromSession,
  clearUserAccount as clearUserAccountFromSession,
} from "../utility/sessionStorageHelper";
import { UserSettingsApiService } from "./apiService";
import { logService } from "./logService";

export function InitUserSettings() {
  const environment = currentEnvironment();
  UserSettingsApiService.get(
    `${environment.customConfiguration.userSettingUrl}/init`
  );
}

export async function getUserReports(
  token: string
): Promise<UserReport[] | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/reports`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      logService.log({
        message: `api call to setting api has failed`,
        type: LogType.Error,
        method: "getUserReports",
        file: "userSettingsService.ts",
      });
      return;
    }

    const userReports = (await response.json()) as UserReport[];
    setUserReports(userReports);

    return userReports;
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserReports",
      file: "userSettingsService.ts",
    });
  }
  return undefined;
}

export function getReports(): UserReport[] {
  return getUserReportsFromSession();
}

export function clearUserReports() {
  clearUserReportsFromSession();
}

export function getUserReportByReportId(
  reportId: string
): UserReport | undefined {
  return getReports().find((f) => f.reportId === reportId);
}

export async function getUserAccount(
  token: string
): Promise<UserAccount | undefined> {
  try {
    const environment = currentEnvironment();
    const path = `${environment.customConfiguration.userSettingUrl}/user/account`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      logService.log({
        message: `api call to setting api to get user account has failed`,
        type: LogType.Error,
        method: "getUserAccount",
        file: "userSettingsService.ts",
      });
      return;
    }

    const userAccount = (await response.json()) as UserAccount;
    setUserAccount(userAccount);

    return userAccount;
  } catch (e) {
    logService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserAccount",
      file: "userSettingsService.ts",
    });
  }
  return undefined;
}

export function getAccount(): UserAccount | undefined {
  return getUserAccountFromSession();
}

export function clearUserAccount() {
  clearUserAccountFromSession();
}
