import { MSTR_PROJECT_ID } from "../config/mstrConfig";
import { DossierEventType, LogType, VisualSize } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { Func } from "../types";
import {
  getAccountingDashboardInstanceId,
  getAccountingReportInstanceId,
  setAccountingDashboardInstanceId,
  setAccountingReportInstanceId,
} from "../utility/sessionStorageHelper";
import { logService } from "./logService";
import { createInstance } from "./mstrRestSevice";

export interface OneViewDossierConfig {
  placeholder?: HTMLElement | null;
  authToken: string;
  pageKey: string;
  isNormalSize: boolean;
  dossierId: string;
  visualId: string;
  errorHandler: (error: any) => void;
  sessionErrorHandler: (error: any) => void;
  mstrLink?: string;
  instanceId?: string;
  isAccountingReport: boolean;
  isAccountingDashboard: boolean;
  isBookmark: boolean;
}

export const getDefaultShareFeature = (): any => {
  return {
    enabled: true,
    invite: false,
    link: false,
    email: false,
    export: true,
    download: false,
    shareDossier: true,
    subscribe: true,
  };
};

export const getEndpointUrl: Func<OneViewDossierConfig, string> = (
  config: OneViewDossierConfig
): string => {
  const { customConfiguration } = currentEnvironment();

  if (!!config.mstrLink) {
    return `${customConfiguration.mstrUrl}/${config.mstrLink}`;
  }

  const url: string = [
    customConfiguration.mstrUrl,
    "app",
    MSTR_PROJECT_ID,
    config.dossierId,
    config.pageKey ?? "",
  ].join("/");

  return url;
};

export const getNavigationBarConfig = (isNormalSize: boolean): any => {
  const navigationBar = {
    enabled: isNormalSize,
    gotoLibrary: false,
    title: isNormalSize,
    toc: isNormalSize,
    reset: isNormalSize,
    reprompt: isNormalSize,
    share: isNormalSize,
    comment: false,
    notification: isNormalSize,
    filter: true,
    options: false,
    search: false,
    bookmark: isNormalSize,
    undoRedo: isNormalSize,
  };

  return navigationBar;
};

export const getDefaultFilterFeature = (): any => {
  const filterFeature = {
    enabled: true,
    edit: true,
    summary: true,
  };

  return filterFeature;
};

export const getLoginToken = (authToken: string): Promise<string> => {
  if (!!authToken?.length) {
    return Promise.resolve(authToken);
  }

  const error = {
    status: 501,
    statusText: "Error executing custom task...",
  };

  return Promise.reject(error);
};

export const getVisualAppearanceConfig = (config: OneViewDossierConfig) => {
  if (config.isNormalSize) return undefined;
  return [
    {
      visualizationKey: config.visualId,
      size: VisualSize.Maximized,
      resizeButtonVisible: false,
    },
  ];
};

export const getDossierProperties = (config: OneViewDossierConfig) => {
  const shareFeature = getDefaultShareFeature();
  const navigationBar = getNavigationBarConfig(config.isNormalSize);
  const filterFeature = getDefaultFilterFeature();
  const customAuthenticationType = getAuthenticationType();
  const visualizationAppearances = getVisualAppearanceConfig(config);
  let instance = undefined;

  if (!!config.instanceId) {
    instance = {
      mid: config.instanceId,
      status: 1,
    };
  }

  const dossierProps = {
    placeholder: config.placeholder,
    url: getEndpointUrl(config),
    shareFeature,
    navigationBar,
    filterFeature,
    errorHandler: config.errorHandler,
    sessionErrorHandler: config.sessionErrorHandler,
    customAuthenticationType,
    getLoginToken: () => getLoginToken(config.authToken),
    dossierFeature: {
      readonly: !config.isNormalSize,
    },
    visualizationAppearances,
    instance,
    enableCustomAuthentication: true,
    enableResponsive: true,
    showErrorPopup: false,
    enableCollaboration: true,
    disableCustomErrorHandlerOnCreate: false,
    disableErrorPopupWindow: true,
    persistViewState: true,
  };

  return dossierProps;
};

export const getDossier = (): any => {
  try {
    return (window as any).microstrategy.dossier;
  } catch (error) {
    console.log("Unable to find dossier from window object.", error);
    return null;
  }
};

export const storeCurrentInstanceId = async (
  instance: any,
  config: OneViewDossierConfig
): Promise<void> => {
  try {
    const id = await (instance.getDossierInstanceId() as Promise<string>);

    config.isAccountingReport
      ? setAccountingReportInstanceId(id)
      : setAccountingDashboardInstanceId(id);
  } catch (error) {
    logService.log({
      message: `${error}`,
      type: LogType.Error,
      method: "storeCurrentInstanceId",
      file: "dossierService.ts",
    });
  }
};

export const getLinkingInfoForAccounting = (
  sourceInstanceId: string,
  config: OneViewDossierConfig
): any => {
  let linkingInfo: any = {
    sourceInstanceId,
    sourceProjectId: MSTR_PROJECT_ID,
    selectorMode: 2,
    passFilter: 1,
  };

  if (config.isAccountingReport) {
    linkingInfo.passInCanvasSelection = true;
    linkingInfo.targetPageKey = config.pageKey;
    linkingInfo.vizSelection = `{"sels":[],"isBranch":false}`;
  }

  return linkingInfo;
};
export const createInstanceForAccounting = async (
  config: OneViewDossierConfig
): Promise<string | undefined> => {
  try {
    const sourceInstanceId = config.isAccountingReport
      ? getAccountingDashboardInstanceId()
      : getAccountingReportInstanceId();

    if (!sourceInstanceId) {
      return undefined;
    }

    const linkingInfo = getLinkingInfoForAccounting(sourceInstanceId, config);

    const payload = {
      filters: null,
      persistViewState: true,
      resolveOnly: false,
      linkingInfo,
      bookmarkInfo: {
        resetManipulation: true,
      },
      disableManipulationsAutoSaving: true,
    };

    const id = (await createInstance(
      config.dossierId,
      payload,
      config.authToken,
    )) as Promise<string>;

    return id;
  } catch (error) {
    logService.log({
      message: `${error}`,
      type: LogType.Error,
      method: "createInstanceForAccounting",
      file: "dossierService.ts",
    });
    return undefined;
  }
};

export const createDossier = async (
  dossierProperties: any
): Promise<any> => {
  try {
    const dossier = await (getDossier().create(
      dossierProperties
    ) as Promise<any>);
    return dossier;
  } catch (error) {
    logService.log({
      message: `${error}`,
      type: LogType.Error,
      method: "createDossier",
      file: "dossierService.ts",
    });
    return Promise.resolve(null);
  }
};

export const destroyDossier = (placeholder: HTMLElement | null) => {
  getDossier().destroy({ placeholder });
};

export const registerEvent = (
  instance: any,
  handler: any,
  dossierEventType: DossierEventType
) => {
  if (!handler) {
    return;
  }
  const dossier = getDossier();
  let eventType;
  switch (dossierEventType) {
    case DossierEventType.GraphicSelecetd:
      eventType = dossier.EventType.ON_GRAPHICS_SELECTED;
      break;
    case DossierEventType.OnLayoutChanged:
      eventType = dossier.EventType.ON_LAYOUT_CHANGED;
      break;
    default:
      break;
  }
  if (eventType) instance.registerEventHandler(eventType, handler);
};

export const getAuthenticationType = (): any => {
  return getDossier().CustomAuthenticationType.AUTH_TOKEN;
};

export const addDossierErrorHandler = (
  instance: any,
  handler: (error: any) => void
) => {
  instance.addCustomErrorHandler(handler, false);
  instance.removeSessionErrorhandler();
  instance.addSessionErrorHandler(handler);
};

export const setVisualizationNormalSizeForReport = async (
  instance: any,
  config: OneViewDossierConfig
) => {
  try {
    if (!config.visualId || !config.isNormalSize) {
      return;
    }

    const currentPageVisualizations =
      await (instance.getCurrentPageVisualizationList() as Promise<any[]>);

    const hasCurrentVisual = currentPageVisualizations.some(
      (v) => v.key === config.visualId
    );

    if (!hasCurrentVisual) return;

    const appearance = {
      visualizationKey: config.visualId,
      size: VisualSize.Normal,
      resizeButtonVisible: true,
    };

    let count = 0;

    const id = setInterval(() => {
      if (++count > 25) {
        clearInterval(id);
        return;
      }

      (instance.changeVisualizationSize(appearance) as Promise<any>)
        .then((_) => {
          clearInterval(id);
        })
        .catch((_error) => {});
    }, 200);
  } catch (error) {
    logService.log({
      message: `Failed to resize the visualization, visual id = ${config.visualId} - ${error}`,
      type: LogType.Error,
      method: "setVisualizationNormalSizeForReport",
      file: "dossierService.ts",
    });
  }
};
